const parallaxSections = document.querySelectorAll('.section-service-bg');

if (parallaxSections && parallaxSections.length > 0) {
    window.addEventListener('scroll', () => {
        parallaxSections.forEach(section => {
            const boundingRect = section.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const percentage = Math.max(
                0,
                Math.min(
                    100,
                    ((viewportHeight - boundingRect.top) /
                        (viewportHeight + boundingRect.height)) *
                        100
                )
            );

            const backgroundDiv = section.querySelector(
                '.section-service-bg__background-image'
            );

            backgroundDiv.style.backgroundPosition = `50% ${parseFloat(
                percentage / 2
            )}%`;
        });
    });
}
